import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { FullScreen } from 'components/templates/Screen';

import { useAuth } from 'libs/AuthContext';
import * as mixins from 'styles/mixins';

function DoctorRoomMain() {
  const {
    completeAppointment,
    screeningAppointment,
    getCompleteAppointment,
    getScreeningAppointment,
    patchRetryAppointment,
  } = useAuth();

  const formatAge = (date: string) => {
    const parsed = Date.parse(date);
    const today = Date.now();
    const diff = today - parsed;
    const age = Math.floor(diff / 31536000000);
    return age;
  };

  const dateFormat = useMemo(() => {
    const dayArray = ['일', '월', '화', '수', '목', '금', '토'];
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const day = today.getDay();
    return `${year}-${month}-${date} (${dayArray[day]})`;
  }, []);

  const startTreatment = (appointmentId: number, status: string) => {
    const options = 'toolbar=no,scrollbars=no,resizable=yes,menubar=no,width=1200, height=900';

    if (status === 'complete') {
      const confirm = window.confirm('진료를 다시 시작하시겠습니까?');
      if (confirm) {
        patchRetryAppointment(appointmentId);
        window.open(`/group-call/?roomId=appointment_${appointmentId}&referrer=login`, '', options);
      }
    }

    if (status === 'screening') {
      window.open(`/group-call/?roomId=appointment_${appointmentId}&referrer=login`, '', options);
    }
  };

  useEffect(() => {
    setInterval(() => {
      console.log('called');
      getScreeningAppointment();
    }, 3000);
    getScreeningAppointment();
  }, []);

  useEffect(() => {
    setInterval(() => {
      console.log('called');
      getCompleteAppointment();
    }, 3000);
    getCompleteAppointment();
  }, []);

  return (
    <FullScreen>
      <Content>
        <Header>오늘예약</Header>
        <Today>{dateFormat}</Today>
        <Container>
          <AppointmentTable>
            <thead>
              <tr>
                <th>접속상태</th>
                <th>시간</th>
                <th>이름(나이/성별)</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {screeningAppointment?.map((appointment, index: number) => {
                const { id, hasPatientEntered, appointmentDate, dateOfBirth, patientGender, patientName } = appointment;
                return (
                  <TableRow key={`appointment-${index}`}>
                    <td>
                      <Indicator active={hasPatientEntered} />
                    </td>
                    <td>{appointmentDate.slice(11, 16)}</td>
                    <td>{`${patientName} (${formatAge(dateOfBirth)}/${patientGender})`}</td>
                    <td>
                      <EnterButton onClick={() => startTreatment(id, 'screening')}>대기실 입장</EnterButton>
                    </td>
                  </TableRow>
                );
              })}
            </tbody>
          </AppointmentTable>
          <div>
            <Header>완료예약</Header>
            <AppointmentTable>
              <thead>
                <tr>
                  <th>시간</th>
                  <th>이름(나이/성별)</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {completeAppointment?.map((appointment, index: number) => {
                  const { id, appointmentDate, dateOfBirth, patientGender, patientName } = appointment;
                  return (
                    <TableRow key={`appointment-${index}`}>
                      <td>{appointmentDate.slice(11, 16)}</td>
                      <td>{`${patientName} (${formatAge(dateOfBirth)}/${patientGender})`}</td>
                      <td>
                        <EnterButton onClick={() => startTreatment(id, 'complete')}>대기실 입장</EnterButton>
                      </td>
                    </TableRow>
                  );
                })}
              </tbody>
            </AppointmentTable>
          </div>
        </Container>
      </Content>
    </FullScreen>
  );
}

export default DoctorRoomMain;

const Indicator = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 8px;
  background-color: ${(props) => (props.active ? '#036ad1' : '#f4f4f4')};
`;

const Content = styled.div`
  ${mixins.flexColumn}
  padding: 72px;
  width: 100%;
  background-color: #f4f4f4;
  height: 100vh;
`;

const Header = styled.h1`
  font-family: NotoSansMedium;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  margin: 0;
  color: var(--grayscale-07);
`;

const Today = styled(Header.withComponent('h3'))`
  font-size: 13px;
  line-height: 19px;
  color: var(--grayscale-05);
  margin: 8px 0 0;
`;

const AppointmentTable = styled.table`
  margin-top: 32px;
  border-collapse: collapse;
  border-spacing: 0 10px;

  tbody:before {
    content: '@';
    display: table-row;
    line-height: 10px;
    text-indent: -99999px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 8px;
    td:last-of-type {
    }
  }

  thead tr {
    th {
      font-family: NotoSansMedium;
      font-size: 13px;
      color: #909090;
      background-color: #eeeeee;
      padding: 11.5px 0 11.5px;
    }
    th:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    th:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  tbody tr {
    td {
      font-family: NotoSansRegular;
      text-align: center;
      font-size: 15px;
      line-height: 32px;
    }
    td:first-of-type {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px 0 0 5px;
    }
    td:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }
`;

const EnterButton = styled.button`
  border-radius: 3px;
  background-color: var(--main-blue-01);
  border: 1px solid var(--main-blue-02);
  color: var(--blue-100);
  padding: 6px 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TableRow = styled.tr`
  background-color: white;
  ${mixins.flexRow}
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 64px;
`;
