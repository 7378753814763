export interface VoidocUser {
  appointmentId?: number;
  fullname: string;
  username: string;
  doctorId?: number;
  uid?: string;
}

export interface CMCAppointment {
  age: number;
  date: string;
  gender: string;
  id: number;
  patientName: string;
  pid: string;
  time: string;
  status: string;
  country: string;
  doctorName: string;
  departmentName: string;
  statusId: number;
}

export interface ForeignerAppointment {
  id: number;
  departmentName: string;
  doctorName: string;
  doctorId: number;
}

export type GuardianAuthInfo = {
  firstName: string;
  gender: string;
  dateOfBirth: string;
  phone: string;
};

export type PatientStatus = {
  [key: string]: boolean;
};

export type IFindInfo = {
  codeSent: boolean;
  username: string;
  phoneNumber: string;
  phoneVerified: boolean;
  findPasswordSuccess: boolean;
  findPasswordFailMsg: string;
};

export type PatientVisitList = {
  id: number;
  appointmentDate: string;
  dateOfBirth: string;
  patientGender: string;
  patientName: string;
};

export interface IScreeningAppointment {
  id: number;
  dateOfBirth: string;
  patientGender: string;
  appointmentDate: string;
  patientName: string;
  hasPatientEntered: boolean;
}

/** Types */
export type State = {
  agoraToken: string;
  appointmentList: CMCAppointment[];
  error: boolean;
  changePasswordSuccess: boolean;
  findInfo: IFindInfo;
  foreignerAppointment: ForeignerAppointment;
  guardianAuthInfo: GuardianAuthInfo;
  guardianSignupErrorMsg: string;
  guardianSignupSuccess: boolean;
  guardianVerifySuccess: boolean;
  isAuthenticated: boolean;
  isCMCDoctor: boolean;
  isDoctor: boolean;
  isForeigner: boolean;
  isGuardian: boolean;
  isPatient: boolean;
  loading: boolean;
  loginMsg: string;
  logoutSuccess: boolean;
  patientName: string;
  patientStatus: PatientStatus;
  patientVisitList: PatientVisitList[]; // ! 제외
  rtcToken: string;
  isUniquePhone: boolean;
  screeningAppointment: IScreeningAppointment[];
  completeAppointment: IScreeningAppointment[];
  readonly user?: VoidocUser;
};

/** State */
export const initialState: State = {
  agoraToken: '',
  appointmentList: [],
  error: false,
  changePasswordSuccess: false,
  findInfo: {
    codeSent: false,
    username: '',
    phoneNumber: '',
    phoneVerified: false,
    findPasswordSuccess: false,
    findPasswordFailMsg: '',
  },
  foreignerAppointment: {
    id: 0,
    departmentName: '',
    doctorName: '',
    doctorId: 0,
  },
  guardianAuthInfo: {
    firstName: '',
    gender: '',
    dateOfBirth: '',
    phone: '',
  },
  guardianSignupErrorMsg: '',
  guardianSignupSuccess: false,
  guardianVerifySuccess: false,
  isAuthenticated: false,
  isCMCDoctor: false,
  isDoctor: false,
  isForeigner: false,
  isGuardian: false,
  isPatient: false,
  isUniquePhone: false,
  loading: true,
  loginMsg: '',
  logoutSuccess: false,
  patientName: '',
  patientStatus: {},
  patientVisitList: [
    {
      id: 0,
      appointmentDate: '',
      dateOfBirth: '',
      patientGender: '',
      patientName: '',
    },
  ],
  rtcToken: '',
  user: {
    appointmentId: 0,
    fullname: '',
    username: '',
    doctorId: 0,
    uid: '',
  },
  screeningAppointment: [],
  completeAppointment: [],
};
