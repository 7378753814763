import { useHistory, useRouteMatch, useLocation, Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'libs/AuthContext';

import { Intermisson } from 'components/organisms/Intermission';
import { useEffect } from 'react';

function DoctorRoute({ component: Component, render = () => null, ...props }: RouteProps) {
  const { url } = useRouteMatch();
  const { isDoctor, loading, getUserInfo } = useAuth();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Route
      {...props}
      render={(renderProps) => {
        if (loading) return <Intermisson />;
        if (!isDoctor) {
          query.set('referrer', url);
          history.push(`/login?${query.toString()}`);
          return null;
        }
        if (Component) return <Component {...renderProps} />;
        return render(renderProps);
      }}
    />
  );
}

export default DoctorRoute;
