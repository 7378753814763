import { createContext } from 'react';
import { GuardianAuthInfo, initialState, State } from './state';
import { LoginInfo } from './types';

/** Context */
export type ContextType = State & {
  checkDuplicatePhone: (phone: string) => Promise<void>;
  findUsername: (username: string, phoneNumber: string) => Promise<void>;
  findPassword: (name: string, username: string, phoneNumber: string) => Promise<void>;
  getVerificationCode: (phoneNumber: string) => Promise<void>;
  getAgoraToken: () => Promise<void>;
  getAppointmentList: (query: string) => Promise<void>;
  getCMCDoctorInfo: (doctorId: number) => Promise<void>;
  getCMCTargetAppointment: (doctorId: number, departmentId: number) => Promise<void>;
  getCompleteAppointment: () => Promise<void>;
  getForeignerAppointment: () => Promise<void>;
  getPatientStatus: (doctorId: number) => Promise<void>;
  getPatientVisitList: () => Promise<void>;
  getUserInfo: () => Promise<void>;
  getRTCToken: (roomId: string) => Promise<void>;
  getScreeningAppointment: () => Promise<void>;
  guardianSignup: (username: string, password: string) => Promise<void>;
  guardianVerify: (authInfo: GuardianAuthInfo) => void;
  initialize: () => void;
  kakaoLogin: (kakaoToken: string) => void;
  login: (loginInfo: LoginInfo) => Promise<void>;
  logout: () => Promise<void>;
  verificationPhone: (code: string, phoneNumber: string) => Promise<void>;
  patientLogin: (username: string) => Promise<void>;
  patchDoctorStatus: (appointmentId: number, hasEntered: boolean) => Promise<void>;
  patchAppointmentStatus: (appointmentId: number, status: number) => Promise<void>;
  patchChangePassword: (password: string, changePassword: string) => Promise<void>;
  patchRetryAppointment: (appointmentId: number) => Promise<void>;
  patchPatientStatus: (appointmentId: number, doctorId: number, hasPatientEntered: boolean) => Promise<void>;
  resetInitailizeStatus: () => void;
  resetRTCToken: () => Promise<void>;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthContext>.');
};

export const initialContext: ContextType = {
  ...initialState,
  // auth methods
  checkDuplicatePhone: stub,
  findUsername: stub,
  findPassword: stub,
  getVerificationCode: stub,
  getAgoraToken: stub,
  getAppointmentList: stub,
  getCMCDoctorInfo: stub,
  getCMCTargetAppointment: stub,
  getCompleteAppointment: stub,
  getForeignerAppointment: stub,
  getPatientStatus: stub,
  getPatientVisitList: stub,
  getRTCToken: stub,
  getScreeningAppointment: stub,
  getUserInfo: stub,
  guardianSignup: stub,
  guardianVerify: stub,
  initialize: stub,
  kakaoLogin: stub,
  login: stub,
  logout: stub,
  verificationPhone: stub,
  patientLogin: stub,
  patchDoctorStatus: stub,
  patchAppointmentStatus: stub,
  patchChangePassword: stub,
  patchPatientStatus: stub,
  patchRetryAppointment: stub,
  resetInitailizeStatus: stub,
  resetRTCToken: stub,
};

const AuthContext = createContext<ContextType>(initialContext);

export default AuthContext;
