import { useAuth } from 'libs/AuthContext';
import styled from 'styled-components';

import { flexCenter, fitImg } from 'styles/mixins';

function MobileSideMenuProfile() {
  const { user } = useAuth();
  return (
    <>
      <Profile>
        <img alt="profile" src="/icons/icon-avatar.svg" />
      </Profile>
      <Name>{user.fullname}</Name>
      <Treatment>{[86, 111, 127, 153].includes(user.doctorId) ? '건강증진센터' : '중환자실'}</Treatment>
    </>
  );
}

export default MobileSideMenuProfile;

const Profile = styled.div`
  ${flexCenter};
  ${fitImg};
  width: 90px;
  height: 90px;
  margin-bottom: 16px;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
`;

const Name = styled.div`
  font-size: 18px;
  line-height: 1.5;
  color: #3e3e3e;
`;

const Treatment = styled.div`
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 1.47;
  color: #909090;
`;
