import { useState, useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';

import { MobileHeader } from 'components/molecules/MobileHeader';
import { MobileSideMenu } from 'components/organisms/MobileSideMenu';
import { LoginPage } from 'components/pages/LoginPage';
import CMCDoctorPage from 'components/pages/CMCDoctorPage/CMCDoctorPage';
import { KakaoLoginPage } from 'components/pages/KakaoLoginPage';
import { VisitRoomScreen } from 'components/pages/VisitRoomScreen';
import { GroupCallScreen } from 'components/pages/GroupCallScreen';
import { DoctorRoomScreen } from 'components/pages/DoctorRoomScreen';
import { FamilyManagementScreen } from 'components/pages/FamilyManagementScreen';
import { PatientListScreen } from 'components/pages/PatientListScreen';
import { PatientInfoScreen } from 'components/pages/PatientInfoScreen';
import { ScheduleScreen } from 'components/pages/ScheduleScreen';
import { SignupPage } from 'components/pages/SignupPage';
import { SelfAuthentication } from 'components/pages/SelfAuthentication';
import { SelfAuthenticationResult } from 'components/pages/SelfAuthenticationResult';
import { FindUsernamePage } from 'components/pages/FindUsernamePage';
import { FindPasswordPage } from 'components/pages/FindPasswordPage';
import { ChangePasswordPage } from 'components/pages/ChangePasswordPage';

import { getScreenHeight } from 'utils';
import { useAuth } from 'libs/AuthContext';

function App() {
  const { isAuthenticated, isDoctor } = useAuth();
  const [showSideMenu, setShowSideMenu] = useState(false);

  useEffect(() => {
    getScreenHeight();
  }, []);

  return (
    <Router>
      {isAuthenticated &&
        isDoctor &&
        !window.location.pathname.includes('group-call') &&
        window.location.pathname !== '/' && <MobileHeader onHamburgerClick={() => setShowSideMenu(!showSideMenu)} />}
      {showSideMenu && isDoctor && <MobileSideMenu onClose={() => setShowSideMenu(false)} />}

      <Switch>
        <Route exact component={LoginPage} path="/" />
        <Route component={CMCDoctorPage} path="/cmc" />
        <Route component={ChangePasswordPage} path="/change-password" />
        <Route component={DoctorRoomScreen} path="/doctor" />
        <Route component={FamilyManagementScreen} path="/guardian" />
        <Route component={GroupCallScreen} path="/group-call" />
        <Route component={PatientListScreen} path="/patient-list" />
        <Route component={PatientInfoScreen} path="/patient-info" />
        <Route component={ScheduleScreen} path="/schedule" />
        <Route component={VisitRoomScreen} path="/visit" />
        <Route component={LoginPage} path="/login" />
        <Route component={LoginPage} path="/ihcc" />
        <Route component={KakaoLoginPage} path="/kakao/auth" />
        <Route component={SignupPage} path="/signup" />
        <Route component={FindUsernamePage} path="/find-username" />
        <Route component={FindPasswordPage} path="/find-password" />
        <Route component={SelfAuthentication} path="/self_authentication/access" />
        <Route component={SelfAuthenticationResult} path="/self_authentication/result" />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
