import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// import { Overlay } from 'components/atoms/Overlay';
import { GroupCall } from 'components/organisms/GroupCall';
import { GroupCallWithShare } from 'components/organisms/GroupCallWithShare';
import { Intermisson } from 'components/organisms/Intermission';
import { MobileGroupCall } from 'components/organisms/MobileGroupCall';
import { FullScreen } from 'components/templates/Screen';

import { useAgora } from 'libs/AgoraContext';
import { useAuth } from 'libs/AuthContext';
import { useCall } from 'libs/CallContext';
import { axiosClient } from 'libs/axios';

import {
  // useNetworkCheck,
  useRTM,
  useMobile,
} from 'hooks';
import { getUrlParameter } from 'utils';

function GroupCallMain() {
  const {
    agoraJoinState,
    connectionStatus,
    initialize: initializeAgora,
    joinAgoraRTC,
    leaveAgoraRTC,
    leaveAgoraScreen,
    localScreenTrack,
    remoteUsers,
    startShareScreen,
    stopShareScreen,
  } = useAgora();

  const {
    agoraToken,
    foreignerAppointment,
    isDoctor,
    rtcToken,
    user,
    getAgoraToken,
    getRTCToken,
    patchAppointmentStatus,
    patchDoctorStatus,
    isPatient,
    logout,
    patchPatientStatus,
  } = useAuth();

  const {
    audioUploaded,
    // channelId,
    resourceId,
    sid,
    isSharing,
    // addChannelId,
    getRecordingInfo,
    startRecording,
    stopRecording,
  } = useCall();

  // const hostIdRef = useRef(getParameter("host"));
  const isMobile = useMobile();
  // const { hasConnection } = useNetworkCheck();
  const { handlePatientJoin, joinRTM, leaveRTM } = useRTM();

  const checkUrlRef = useRef(
    getUrlParameter('referrer') === 'visit' ||
      getUrlParameter('referrer') === 'guardian' ||
      getUrlParameter('referrer') === 'cmc' ||
      getUrlParameter('referrer') === 'login',
  );
  const roomIdRef = useRef(getUrlParameter('roomId'));

  const [showSelectMenu, setShowSelectMenu] = useState(false);

  const closeConnection = async () => {
    checkUrlRef.current = false;
    roomIdRef.current = '';
    await leaveAgoraScreen();
    stopRecording();
    leaveRTM();
    await leaveAgoraRTC();
    initializeAgora();
  };

  const handleEndBtnClick = async () => {
    try {
      await closeConnection();
    } catch (e) {
      console.error('failed to leaven screen : ', e);
    }

    if (isDoctor) {
      if ([86, 111, 127, 153].includes(user.doctorId)) {
        try {
          await updateAppointmentStatus(2);
        } catch (e) {
          console.error('Status update error: ', e);
        }

        window.close();
      }
    }
  };

  const handleSelectMenu = () => {
    setShowSelectMenu(!showSelectMenu);
  };

  const handleShareBtnClick = () => {
    if (isMobile) {
      alert('PC에서만 지원하는 기능입니다');
      return;
    }
    if (!isSharing) {
      startShareScreen(roomIdRef.current, rtcToken);
      return;
    }

    if (localScreenTrack) {
      stopShareScreen();
      return;
    }

    alert('다른 참가자가 공유 중입니다.');
  };

  const updateAppointmentStatus = async (status: number) => {
    const appointmentId = getUrlParameter('roomId').match(/\d+/);
    await patchAppointmentStatus(parseInt(appointmentId[0]), status);
  };

  useEffect(() => {
    if (!rtcToken || agoraJoinState || !agoraToken) return;
    const roomId = roomIdRef.current;

    if (!checkUrlRef.current && !connectionStatus) return;
    joinAgoraRTC(roomId, rtcToken, isDoctor ? user.doctorId : parseInt(user.uid));
    joinRTM();
    // if (allowJoined) {
    //   join(roomId, rtcToken, user.uid);
    // }

    // eslint-disable-next-line
  }, [agoraToken, rtcToken, agoraJoinState /* allowJoined */, connectionStatus, isDoctor]);

  useEffect(() => {
    getRTCToken(roomIdRef.current);
    getAgoraToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.onbeforeunload = async () => {
      const cmcDoctorId = getUrlParameter('orddrid');

      if ((!checkUrlRef.current && !isPatient) || !!cmcDoctorId) {
        await axiosClient.put('visit/connection_status/', {
          doctor_id: cmcDoctorId,
        });
      }

      if (foreignerAppointment.id) {
        await patchPatientStatus(foreignerAppointment.id, foreignerAppointment.doctorId, false);
        await logout();
      }

      if (isDoctor) {
        if ([86, 111, 127, 153].includes(user.doctorId)) {
          try {
            const appointmentId = getUrlParameter('roomId').match(/\d+/);
            patchDoctorStatus(parseInt(appointmentId[0]), false);
          } catch (e) {
            console.error('Status update error: ', e);
          }

          window.close();
          return;
        }
      }

      await leaveAgoraScreen();
      await leaveAgoraRTC();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isDoctor) return;

    const remoteCount = remoteUsers.filter((remoteUser) => String(remoteUser.uid) !== '111111').length;

    if (remoteCount) {
      if (isDoctor) {
        if ([86, 111, 127, 153].includes(user.doctorId)) {
          try {
            updateAppointmentStatus(5);
          } catch (e) {
            console.error('Status update error: ', e);
          }
        }
      }
      if (sid) return;
      const roomId = getUrlParameter('roomId');
      startRecording(roomId, resourceId);
    }

    // eslint-disable-next-line
  }, [isDoctor, remoteUsers]);

  useEffect(() => {
    if (!isDoctor) return;

    if (audioUploaded) {
      // doctorLogout();
    }

    // eslint-disable-next-line
  }, [audioUploaded, isDoctor]);

  useEffect(() => {
    const roomId = getUrlParameter('roomId');
    // if (!isDoctor) return;

    getRecordingInfo(roomId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const appointmentId = getUrlParameter('roomId').match(/\d+/);
    patchDoctorStatus(parseInt(appointmentId[0]), true);
  }, []);

  if ((!checkUrlRef.current && isDoctor && !connectionStatus) || !agoraJoinState) return <Intermisson />;

  return (
    <Wrapper>
      {isSharing ? (
        <GroupCallWithShare
          handleEndBtnClick={handleEndBtnClick}
          handlePatientJoin={handlePatientJoin}
          handleSelectMenu={handleSelectMenu}
          handleShareBtnClick={handleShareBtnClick}
          showSelectMenu={showSelectMenu}
        />
      ) : (
        <>
          {isMobile ? (
            <MobileGroupCall
              handleEndBtnClick={handleEndBtnClick}
              // handlePatientJoin={handlePatientJoin}
              // joinMessage={joinMessage}
              // handleShareBtnClick={handleShareBtnClick}
            />
          ) : (
            <GroupCall
              handleEndBtnClick={handleEndBtnClick}
              handleSelectMenu={handleSelectMenu}
              handleShareBtnClick={handleShareBtnClick}
              showSelectMenu={showSelectMenu}
            />
          )}
        </>
      )}
    </Wrapper>
  );
}

export default GroupCallMain;

const Wrapper = styled(FullScreen)`
  background-color: var(--navy-900);

  @media only screen and (max-width: 599px) {
    background-color: #424242;
  }

  @media (orientation: landscape) {
    background-color: #424242;
  }
`;
