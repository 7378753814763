import { ReactElement, useEffect, useReducer, useRef } from 'react';
import AgoraRTC, { IAgoraRTCRemoteUser, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import VirtualBackgroundExtension from 'agora-extension-virtual-background';

import AgoraContext, { ContextType } from './context';
import reducer from './reducer';
import { initialState } from './state';
import useSocket from 'hooks/useSocket';
import { useCall } from 'libs/CallContext';
import { VolumeIndicatorInfo } from './types';
import { getUrlParameter } from 'utils';
import { axiosClient } from 'libs/axios';

function AgoraProvider({ children }: { children: ReactElement }): JSX.Element {
  const { updateSharing } = useCall();
  const { emitSocketEvent, listenSocketEvent, disconnectSocket } = useSocket();

  const extensionRef = useRef(null);
  const processorRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    agoraRTCClient,
    agoraScreenClient,
    facingMode,
    remoteFacingMode,
    localAudioTrack,
    localScreenTrack,
    localVideoTrack,
    speaker,
    remoteUsers,
  } = state;

  const createLocalTracks = async (): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> => {
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(
      {
        AEC: true,
        ANS: true,
      },
      {
        encoderConfig: '1080p_1',
        optimizationMode: 'detail',
        facingMode: 'user',
      },
    );

    dispatch({
      type: 'SET_LOCAL_TRACKS',
      payload: {
        localAudioTrack: microphoneTrack,
        localVideoTrack: cameraTrack,
      },
    });

    return [microphoneTrack, cameraTrack];
  };

  const getProcessorInstance = async () => {
    if (!processorRef.current && localVideoTrack) {
      processorRef.current = extensionRef.current.createProcessor();
      try {
        await processorRef.current.init('/wasm');
      } catch (e) {
        console.error('Fail to load WASM resource: ', e);
      }
      localVideoTrack.pipe(processorRef.current).pipe(localVideoTrack.processorDestination);
    }

    return processorRef.current;
  };

  const initialize = () => {
    dispatch({ type: 'INITIALIZE' });
  };

  const checkConnectionStatus = async (doctorId: number) => {
    const checkUrl: boolean = getUrlParameter('referrer') === 'visit' || getUrlParameter('referrer') === 'guardian';
    if (checkUrl) {
      try {
        await axiosClient.post('visit/connection_status/', {
          doctor_id: doctorId,
        });

        dispatch({ type: 'SET_CONNECTION_STATUS', payload: true });
      } catch (e) {
        dispatch({ type: 'SET_CONNECTION_STATUS', payload: false });
        alert('이미 화상 연결 중입니다.');
        window.close();
        console.error('check connection error', e);
      }
    }
  };

  const joinAgoraRTC = async (channel: string, token: string, uid: string | number) => {
    try {
      const extension = new VirtualBackgroundExtension();

      AgoraRTC.registerExtensions([extension]);

      extensionRef.current = extension;

      const [microphoneTrack, cameraTrack] = await createLocalTracks();

      if (agoraRTCClient.connectionState === 'CONNECTING' || agoraRTCClient.connectionState !== 'CONNECTED') {
        await agoraRTCClient.join(process.env.REACT_APP_AGORA_APP_ID, channel, token || null, uid);
      }

      agoraRTCClient.enableAudioVolumeIndicator();

      if (!/Android|Mobi|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        try {
          await agoraRTCClient.enableDualStream();
        } catch (e) {
          console.error('dualstream');
        }
      }

      agoraRTCClient.setLowStreamParameter({
        height: 400,
        width: 640,
        framerate: 5,
      });

      await agoraRTCClient.publish([microphoneTrack, cameraTrack]);
      emitSocketEvent('joinRoom', channel);

      (window as any).client = agoraRTCClient;
      (window as any).videoTrack = cameraTrack;

      dispatch({ type: 'SET_AGORA_JOIN_STATE', payload: true });
      dispatch({ type: 'SET_SOCKET_JOIN_STATE', payload: true });
    } catch (error) {
      console.error('agora join error', error);
    }
  };

  const joinAgoraScreen = async (channel: string, token: string) => {
    if (!agoraScreenClient) return;
    try {
      await agoraScreenClient.join(process.env.REACT_APP_AGORA_APP_ID, channel, token || null, 111111);

      if (!/Android|Mobi|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        await agoraScreenClient.enableDualStream();
      }

      agoraScreenClient.setLowStreamParameter({
        height: 400,
        width: 640,
        framerate: 5,
      });
    } catch (error) {
      console.error('screen join error: ', error);
    }
  };

  const leaveAgoraRTC = async () => {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    dispatch({ type: 'SET_REMOTE_USERS', payload: [] });
    dispatch({ type: 'SET_AGORA_JOIN_STATE', payload: false });

    await agoraRTCClient?.unpublish([localAudioTrack, localVideoTrack]);
    await agoraRTCClient?.leave();

    disconnectSocket();

    dispatch({ type: 'SET_SOCKET_JOIN_STATE', payload: false });
  };

  const leaveAgoraScreen = async () => {
    if (!/Android|Mobi|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      await agoraScreenClient.disableDualStream();
    }
    await agoraScreenClient?.leave();
  };

  const removeBackground = () => {
    localVideoTrack.unpipe();
    dispatch({
      type: 'SET_VIRTUAL_BACKGROUND_ENABLED',
      payload: '',
    });
  };

  const rotateCamera = async () => {
    const rtcUsers = remoteUsers.filter((user) => String(user.uid) !== '111111');

    if (remoteFacingMode === 'environment' || !rtcUsers.length) {
      alert('지금은 사용할 수 없습니다.');
      return;
    }

    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    localVideoTrack.close();

    const cameraTrack = await AgoraRTC.createCameraVideoTrack({
      encoderConfig: '1080p_1',
      optimizationMode: 'detail',
      facingMode: newFacingMode,
    });

    if (newFacingMode === 'environment') {
      emitSocketEvent('useRearCamera');
    } else {
      emitSocketEvent('useFrontCamera');
    }

    await agoraRTCClient.unpublish(localVideoTrack);
    await agoraRTCClient.publish(cameraTrack);

    dispatch({
      type: 'SET_LOCAL_VIDEO_TRACK',
      payload: {
        facingMode: newFacingMode,
        localVideoTrack: cameraTrack,
      },
    });
  };

  const setBackgroundBlurring = async () => {
    processorRef.current = await getProcessorInstance();

    if (processorRef.current) {
      localVideoTrack.pipe(processorRef.current);
    }

    try {
      processorRef.current.setOptions({ type: 'blur', blurDegree: 3 });
      await processorRef.current.enable();
      dispatch({
        type: 'SET_VIRTUAL_BACKGROUND_ENABLED',
        payload: 'blur',
      });
    } catch (e) {
      console.error('Fail to change background blurDegree: ', e);
    }
  };

  const setBackgroundImage = async (filePath: string) => {
    processorRef.current = await getProcessorInstance();

    if (processorRef.current) {
      localVideoTrack.pipe(processorRef.current);
    }

    const imgElement = document.createElement('img');
    imgElement.crossOrigin = 'anonymous';
    imgElement.alt = 'background';
    imgElement.src = filePath;

    imgElement.onload = async () => {
      try {
        processorRef.current.setOptions({ type: 'img', source: imgElement });
        await processorRef.current.enable();
        dispatch({
          type: 'SET_VIRTUAL_BACKGROUND_ENABLED',
          payload: 'img',
        });
        localStorage.setItem('background', filePath);
      } catch (e) {
        console.error('Fail to change background image: ', e);
      }
    };
  };

  const startShareScreen = async (channel: string, token: string) => {
    const isMacOS = () => navigator.userAgent.toLocaleLowerCase().indexOf('mac') > 0;

    const handleConfirmAlert = () => {
      const confirm = window.confirm('화면공유를 사용할 수 없습니다. \n시스템 권한을 허용하시겠습니까?');

      if (confirm) {
        window.open('x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture');
      } else {
        alert('화면공유가 취소되었습니다.');
      }
    };

    try {
      const screenTrack = await AgoraRTC.createScreenVideoTrack({
        encoderConfig: '1080p_1',
        optimizationMode: 'detail',
      });
      if (!Array.isArray(screenTrack)) {
        dispatch({
          type: 'SET_SCREEN_TRACK',
          payload: screenTrack,
        });

        await joinAgoraScreen(channel, token);
        await agoraScreenClient.publish(screenTrack);
      }
    } catch (error) {
      const { code, message } = error;
      if (code === 'PERMISSION_DENIED' && message.indexOf('by system') > 0) {
        if (isMacOS) {
          handleConfirmAlert();
        } else {
          alert('화면공유를 사용할 수 없습니다. \n시스템 권한을 허용해주세요.');
        }
      } else {
        alert('화면공유가 취소되었습니다.');
        console.error('screen share error: ', error);
      }

      stopShareScreen();
    }
  };

  const stopShareScreen = async () => {
    updateSharing(false);

    dispatch({
      type: 'SET_SCREEN_TRACK',
      payload: null,
    });

    await agoraScreenClient.unpublish(localScreenTrack);
    await leaveAgoraScreen();
  };

  const toggleAudio = () => {
    // if (localAudioTrack.muted) {
    //   localAudioTrack.setMuted(false);
    // } else {
    //   localAudioTrack.setMuted(true);
    // }
    localAudioTrack.setMuted(!localAudioTrack.muted);

    dispatch({
      type: 'TOGGLE_AUDIO',
      payload: !localAudioTrack.muted,
    });
  };

  const toggleVideo = () => {
    // if (localVideoTrack.muted) {
    //   localVideoTrack.setMuted(false);
    // } else {
    //   localVideoTrack.setMuted(true);
    // }
    localVideoTrack.setMuted(!localVideoTrack.muted);

    dispatch({
      type: 'TOGGLE_VIDEO',
      payload: !localVideoTrack.muted,
    });
  };

  useEffect(() => {
    if (!agoraRTCClient || !localVideoTrack) return;

    const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      await agoraRTCClient.subscribe(user, mediaType);

      dispatch({
        type: 'SET_REMOTE_USERS',
        payload: Array.from(agoraRTCClient.remoteUsers),
      });
    };

    // eslint-disable-next-line
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      dispatch({
        type: 'SET_REMOTE_USERS',
        payload: Array.from(agoraRTCClient.remoteUsers),
      });
    };

    // eslint-disable-next-line
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      dispatch({
        type: 'SET_REMOTE_USERS',
        payload: Array.from(agoraRTCClient.remoteUsers),
      });
      if (String(user.uid) === '111111') {
        updateSharing(true);
      }
    };

    // eslint-disable-next-line
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      dispatch({
        type: 'SET_REMOTE_USERS',
        payload: Array.from(agoraRTCClient.remoteUsers),
      });
      if (String(user.uid) === '111111') {
        updateSharing(false);
      }
    };

    const handleSpeakerChange = (users: VolumeIndicatorInfo[]) => {
      const rtcUsers = users.filter((user) => String(user.uid) !== '111111');
      let result = 0;
      rtcUsers.forEach((user) => {
        if (user.level > result) {
          result = user.level;
        }
      });

      const speakerId = rtcUsers.find((user) => user.level === result)?.uid;
      if (!speakerId) return;
      if (speaker === speakerId) return;

      dispatch({ type: 'SET_SPEAKER', payload: speakerId });
    };

    agoraRTCClient.on('user-published', handleUserPublished);
    agoraRTCClient.on('user-unpublished', handleUserUnpublished);
    agoraRTCClient.on('user-joined', handleUserJoined);
    agoraRTCClient.on('user-left', handleUserLeft);
    agoraRTCClient.on('volume-indicator', handleSpeakerChange);
    // agoraRTCClient.on('s')

    listenSocketEvent('remoteRearCamera', () => {
      dispatch({ type: 'SET_REMOTE_FACING_MODE', payload: 'environment' });
    });

    listenSocketEvent('remoteFrontCamera', () => {
      dispatch({ type: 'SET_REMOTE_FACING_MODE', payload: 'user' });
    });

    // eslint-disable-next-line
    return () => {
      agoraRTCClient.off('user-published', handleUserPublished);
      agoraRTCClient.off('user-unpublished', handleUserUnpublished);
      agoraRTCClient.off('user-joined', handleUserJoined);
      agoraRTCClient.off('user-left', handleUserLeft);
      agoraRTCClient.off('volume-indicator', handleSpeakerChange);
    };
  }, [agoraRTCClient, localVideoTrack, speaker]);

  // eslint-disable-next-line
  const agoraContext: ContextType = {
    ...state,
    initialize,
    joinAgoraRTC,
    joinAgoraScreen,
    leaveAgoraRTC,
    leaveAgoraScreen,
    removeBackground,
    rotateCamera,
    setBackgroundBlurring,
    setBackgroundImage,
    startShareScreen,
    stopShareScreen,
    toggleAudio,
    toggleVideo,
    checkConnectionStatus,
  };

  return <AgoraContext.Provider value={agoraContext}>{children}</AgoraContext.Provider>;
}

export default AgoraProvider;
