import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useAuth } from 'libs/AuthContext';

interface MobileSideMenuListProps {
  onClose: () => void;
}

function MobileSideMenuList({ onClose }: MobileSideMenuListProps) {
  const { user, logout } = useAuth();
  const history = useHistory();

  const handleBtnClick = (url: string) => {
    onClose();
    history.push(url);
  };

  return (
    <SideMenuList>
      {![86, 111, 127, 153].includes(user.doctorId) && (
        <>
          <SideMenuListItem isSelected={window.location.pathname === '/visit'} onClick={() => handleBtnClick('/visit')}>
            면회관리
          </SideMenuListItem>
          <SideMenuListItem
            isSelected={window.location.pathname === '/schedule'}
            onClick={() => handleBtnClick('/schedule')}
          >
            일정관리
          </SideMenuListItem>
          <SideMenuListItem
            isSelected={window.location.pathname === '/patient-info'}
            onClick={() => handleBtnClick('/patient-info')}
          >
            환자등록
          </SideMenuListItem>
          <SideMenuListItem
            isSelected={window.location.pathname === '/patient-list'}
            onClick={() => handleBtnClick('/patient-list')}
          >
            환자관리
          </SideMenuListItem>
        </>
      )}
      <SideMenuListItem isSelected={false} onClick={() => logout()}>
        로그아웃
      </SideMenuListItem>
    </SideMenuList>
  );
}

export default MobileSideMenuList;

interface SideMenuListProps {
  isSelected: boolean;
}

const SideMenuList = styled.nav`
  padding: 0;
  list-style: none;
`;

const SideMenuListItem = styled.a<SideMenuListProps>`
  width: 100%;
  height: 27px;
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--gray-500);
  cursor: pointer;

  &:hover {
    color: #2685fd;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #2685fd;
    `}
`;
